import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MenuLink from 'components/shared/MenuLink/MenuLink';
import Button from 'components/shared/Button/Button';
import LazyImage from 'components/shared/lazyImage';

import styles from './navbar.module.scss';

import Modal from 'components/shared/modal';
import confetti from 'assets/images/confetti.png';
import { NavLogo } from 'assets/images';

function Navbar(props) {
	const [active, setActive] = useState(false);
	const [section, setSection] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { links, btnName, btnClassName, showBanner, place } = props;
	const navigate = useNavigate();
	console.log('links navbar', links);

	function goToHash(id) {
		if (id === 'modal') {
			setIsModalOpen(true); // Open modal if id is 'modal'
		} else {
			if (place === 'All Course') {
				navigate('/', { state: { sectionId: id } });
			}

			const element = document.querySelector(`#${id}`);
			const header = document.querySelector('#header');

			if (element) {
				window.scrollTo({ top: element.offsetTop - header.offsetHeight + 70 });
				setSection(id);
			}
			setActive(false);
		}
	}

	return (
		<header className={`${styles.header} ${showBanner ? styles.headerBanner : null}`} id='header'>
			{showBanner && (
				<article className={styles.couponBanner}>
					<img src={confetti} className={styles.left} alt='celebration' />
					<p>
						Apply coupon <b>EARLYBIRD</b> and get discount of <b>₹500</b> on every course!
					</p>
					<img src={confetti} className={styles.right} alt='celebration' />
				</article>
			)}
			<main>
				<aside className={styles['left-side']}>
					<LazyImage src={NavLogo} alt='CipherSchools' onClick={() => navigate('/')} />
					<div onClick={() => navigate('/')}>
						<h1>
							Cipher<span>Schools</span>
						</h1>
						<p>Your learning buddy</p>
					</div>
				</aside>

				<aside className={styles.menu}>
					<nav className={styles['middle-side']}>
						{links &&
							links.map((link, i) => (
								<MenuLink
									key={i}
									linkName={link.name}
									section={section}
									func={() => goToHash(link.type === 'modal' ? 'modal' : link.id)}
									id={`LP-NB-${link.name}`}
								/>
							))}
					</nav>
				</aside>

				<aside className={styles['mob-nav']}>
					{(links || btnName) && (
						<div className={styles.icons} data-active={active} onClick={() => setActive(prev => !prev)}>
							<svg
								width='1em'
								height='1em'
								viewBox='0 0 30 22'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								className={styles.opened}>
								<path
									d='M30 20.0075C30 20.8325 29.331 21.5 28.5075 21.5H16.4925C16.0967 21.5 15.717 21.3428 15.4371 21.0629C15.1572 20.783 15 20.4033 15 20.0075C15 19.6117 15.1572 19.232 15.4371 18.9521C15.717 18.6722 16.0967 18.515 16.4925 18.515H28.5075C29.3325 18.515 30 19.1825 30 20.0075ZM30 11C30 11.825 29.331 12.4925 28.5075 12.4925H1.4925C1.09666 12.4925 0.717041 12.3353 0.437143 12.0554C0.157245 11.7755 0 11.3958 0 11C0 10.6042 0.157245 10.2245 0.437143 9.94464C0.717041 9.66475 1.09666 9.5075 1.4925 9.5075H28.5075C29.3325 9.5075 30 10.1765 30 11ZM28.5075 3.485C28.9033 3.485 29.283 3.32775 29.5629 3.04786C29.8428 2.76796 30 2.38834 30 1.9925C30 1.59666 29.8428 1.21704 29.5629 0.937143C29.283 0.657245 28.9033 0.5 28.5075 0.5H10.4925C10.2965 0.5 10.1024 0.538605 9.92134 0.61361C9.74027 0.688615 9.57573 0.798551 9.43714 0.937143C9.29855 1.07573 9.18861 1.24027 9.11361 1.42135C9.0386 1.60242 9 1.7965 9 1.9925C9 2.1885 9.0386 2.38258 9.11361 2.56366C9.18861 2.74473 9.29855 2.90927 9.43714 3.04786C9.57573 3.18645 9.74027 3.29638 9.92134 3.37139C10.1024 3.44639 10.2965 3.485 10.4925 3.485H28.5075Z'
									fill='currentColor'
								/>
							</svg>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='1em'
								height='1em'
								preserveAspectRatio='xMidYMid meet'
								viewBox='0 0 24 24'
								className={styles.closed}>
								<path
									fill='currentColor'
									d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2Zm0 1.5a8.5 8.5 0 1 0 0 17a8.5 8.5 0 0 0 0-17Zm3.446 4.897l.084.073a.75.75 0 0 1 .073.976l-.073.084L13.061 12l2.47 2.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L12 13.061l-2.47 2.47a.75.75 0 0 1-.976.072l-.084-.073a.75.75 0 0 1-.073-.976l.073-.084L10.939 12l-2.47-2.47a.75.75 0 0 1-.072-.976l.073-.084a.75.75 0 0 1 .976-.073l.084.073L12 10.939l2.47-2.47a.75.75 0 0 1 .976-.072Z'
								/>
							</svg>
						</div>
					)}
					<nav className={`${styles['middle-side']} ${styles.mob}`} data-active={active}>
						<div>
							{links &&
								links.map((link, i) => (
									<MenuLink
										key={i}
										linkName={link.name}
										section={section}
										func={() => goToHash(link.type === 'modal' ? 'modal' : link.id)}
										id={`LP-NB-${link.name}`}
									/>
								))}
							{/* {btnName && (
								<Button
									func={() => goToHash(btnName === 'Open Modal' ? 'modal' : btnName)}
									name={btnName}
									className={btnClassName}
								/>
							)} */}
						</div>
					</nav>
				</aside>
			</main>
			<Modal
				showModal={isModalOpen}
				setShowModal={setIsModalOpen}
				heading='Queries'
				className='custom-modal-class'
				placement='center'>
				<p>Q. How can I make a payment for this Track, and what payment methods are accepted?</p>
				<p>
					- Payments for this Track can be made securely through our website. We accept various payment methods, including
					credit/debit cards, net banking, UPI, and digital wallets
				</p>
			</Modal>
		</header>
	);
}

Navbar.propTypes = {
	btnName: PropTypes.string,
	btnClassName: PropTypes.string,
	links: PropTypes.array,
	showBanner: PropTypes.bool,
	place: PropTypes.string,
};

export default Navbar;
